import { useDayPicker } from "react-day-picker";

import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";

import dayjs from "dayjs";

import { Button } from "../../../content/button";

const IconLeft = () => <ChevronLeftIcon className="h-4 w-4" />;
const IconRight = () => <ChevronRightIcon className="h-4 w-4" />;

interface CalendarCaptionProps {
  displayMonth: Date;
}

export const CalendarCaption = ({ ...props }: CalendarCaptionProps) => {
  const {
    onMonthChange,
    month,
    components: { CaptionLabel } = {},
  } = useDayPicker();

  const handleChangeDate = (date: Date) => {
    onMonthChange?.(date);
  };

  return (
    <div className={"flex items-center gap-4"}>
      <Button
        variant={"outline"}
        className={
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 text-sm"
        }
        onClick={() => {
          const newMonth = dayjs(month).subtract(1, "month").toDate();
          handleChangeDate(newMonth);
        }}
      >
        <IconLeft />
      </Button>

      {CaptionLabel && <CaptionLabel {...props} />}

      <Button
        variant={"outline"}
        className={
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 text-sm"
        }
        onClick={() => {
          const newMonth = dayjs(month).add(1, "month").toDate();
          handleChangeDate(newMonth);
        }}
      >
        <IconRight />
      </Button>
    </div>
  );
};
