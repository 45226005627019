import { FC, HTMLAttributes, useContext } from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";

import { Button } from "../button";
import FilterableTableContext from "./filterable-table-context";

interface ClearFiltersButtonProps extends HTMLAttributes<HTMLButtonElement> {
  onClear?: () => void;
  forceShow?: boolean;
  ignoreClearOn?: string[];
}

export const ClearFiltersButton: FC<ClearFiltersButtonProps> = ({
  forceShow,
  onClear,
  ignoreClearOn = [],
  ...rest
}) => {
  const { filter, filterValues } = useContext(FilterableTableContext);

  const filtersWithValue: Record<string, string[]> = {};
  for (const key in filterValues) {
    if (filterValues[key]?.length > 0) {
      filtersWithValue[key] = filterValues[key];
    }
  }

  const isIgnoredEqualFiltered =
    ignoreClearOn.length === Object.values(filtersWithValue).length &&
    ignoreClearOn.every((key) => filtersWithValue[key]?.length > 0);

  const isFiltersApplied = Object.values(filterValues).some(
    (values) => values?.length > 0
  );

  const shouldShow = forceShow || (isFiltersApplied && !isIgnoredEqualFiltered);

  const prepareFilterValues = () => {
    const newFilterValues: Record<string, string[]> = {};
    ignoreClearOn.forEach((key) => {
      newFilterValues[key] = filterValues[key];
    });
    filter?.(newFilterValues);
    onClear?.();
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <Button
      variant={"ghost"}
      className={"flex items-center gap-2.5 max-h-[36px] min-w-[180px]"}
      onClick={prepareFilterValues}
      {...rest}
    >
      <span>Clear Results</span>
      <XMarkIcon className={"w-4 h-4"} />
    </Button>
  );
};
