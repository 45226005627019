import { FC, HTMLAttributes, ReactNode } from "react";

interface TopBarProps extends HTMLAttributes<HTMLDivElement> {
  rightSlot?: ReactNode;
  bottomSlot?: ReactNode;
}

export const TopBar: FC<TopBarProps> = ({
  children,
  rightSlot,
  bottomSlot,
  ...rest
}) => {
  return (
    <div className="flex flex-col gap-2" {...rest}>
      <div className={"flex justify-between flex-wrap"}>
        <div className="flex items-center gap-4 flex-wrap gap-y-2">
          {children}
        </div>
        <div>{rightSlot}</div>
      </div>
      {bottomSlot}
    </div>
  );
};
