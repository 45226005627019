import {
  cloneElement,
  FC,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from "react";

import { cn } from "../../../utils";

interface TabSelectorOption {
  label?: ReactNode;
  value: string;
  icon?: ReactNode;
  enabledIconColor?: string;
}

interface TabsSelectorProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onSelect"> {
  value: string;
  options: TabSelectorOption[];
  onSelect: (id: string) => void;
}

export const TabsSelector: FC<TabsSelectorProps> = ({
  options,
  onSelect,
  value,
  ...rest
}) => {
  return (
    <div className={"flex p-1 bg-muted rounded-[6px]"} {...rest}>
      {options.map(({ value: itemValue, icon, label, enabledIconColor }) => (
        <button
          key={itemValue}
          onClick={() => onSelect(itemValue)}
          className={cn(
            "flex gap-1 items-center justify-center py-1 px-2 bg-transparent rounded-[3px]",
            value === itemValue && "bg-background border border-muted shadow-md"
          )}
        >
          {cloneElement(icon as ReactElement, {
            "data-testid": `icon-${itemValue}`,
            className: cn(
              `w-4 h-4 text-muted-foreground text-${enabledIconColor} opacity-50`,
              value === itemValue && "opacity-100"
            ),
          })}
          {label && (
            <span
              className={
                "text-[12px] font-normal text-muted-foreground leading-[13px]"
              }
            >
              {label}
            </span>
          )}
        </button>
      ))}
    </div>
  );
};
