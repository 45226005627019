import { Children, cloneElement, ReactElement, useEffect, useRef } from "react";

import { motion } from "framer-motion";

import { cn } from "../../utils";

interface SlideTransitionProps {
  status: string;
  statuses: { status: string; element: ReactElement }[];
}

const variants = {
  active: {
    x: [-100, 0],
    zIndex: [0, 1],
  },
  inactive: {
    x: [0],
    zIndex: [1, 0],
  },
  invisible: {
    x: [-100],
    zIndex: [0],
  },
};

const usePrevious = (value: string) => {
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const SlideTransition = ({
  status: currentStatus,
  statuses,
}: SlideTransitionProps) => {
  const previousStatus = usePrevious(currentStatus);

  const getAnimationStatus = (status: string = currentStatus) => {
    if (currentStatus === status) {
      return "active";
    }

    if (previousStatus === status) {
      return "inactive";
    }

    return "invisible";
  };

  return (
    <div className="relative w-full h-full overflow-x-hidden">
      {statuses.map(({ status, element }) => {
        const animationStatus = getAnimationStatus(status);

        return (
          <motion.div
            key={status}
            variants={variants}
            animate={animationStatus}
            data-testid={`status-${status}`}
            data-animation-status={animationStatus}
            className={cn("absolute top-0 left-0 w-full h-full")}
          >
            {Children.map(element, (child) => {
              return cloneElement(child, {
                className: "w-full h-full",
              });
            })}
          </motion.div>
        );
      })}
    </div>
  );
};
