"use client";

import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  PropsWithChildren,
} from "react";

import { Content, List, Root, Trigger } from "@radix-ui/react-tabs";

import { cn } from "../../utils";

const Tabs = Root;

const TabsList = forwardRef<
  ElementRef<typeof List>,
  ComponentPropsWithoutRef<typeof List>
>(({ className, ...props }, ref) => (
  <List
    ref={ref}
    className={cn(
      "inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground",
      className
    )}
    {...props}
  />
));

TabsList.displayName = List.displayName;

export interface TabsTriggerProps
  extends PropsWithChildren<ComponentPropsWithoutRef<typeof Trigger>> {
  badge?: string;
}

const TabsTrigger = forwardRef<ElementRef<typeof Trigger>, TabsTriggerProps>(
  ({ className, children, badge, ...props }, ref) => (
    <Trigger
      ref={ref}
      className={cn(
        "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm",
        className
      )}
      {...props}
    >
      {badge && (
        <span className="text-blue-700 border-r-2 pr-2 mr-2 border-border">
          {badge}
        </span>
      )}
      {children}
    </Trigger>
  )
);

TabsTrigger.displayName = Trigger.displayName;

const TabsContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content>
>(({ className, ...props }, ref) => (
  <Content
    ref={ref}
    className={cn(
      "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    )}
    role="tabpanel"
    {...props}
  />
));

TabsContent.displayName = Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
