import { FC } from "react";

import { twMerge } from "tailwind-merge";

import { Input, InputProps } from "./input";

interface FormFieldProps extends Omit<InputProps, "id"> {
  id: string;
  label?: string;
  labelClassName?: string;
  containerClassName?: string;
  inputContainerClassName?: string;
  error?: string;
}

export const FormField: FC<FormFieldProps> = ({
  label,
  labelClassName = "",
  containerClassName = "",
  inputContainerClassName = "",
  ...props
}) => (
  <div className={twMerge("flex flex-col gap-1", containerClassName)}>
    {label && (
      <label
        htmlFor={props.id}
        className={twMerge("text-sm text-muted-foreground", labelClassName)}
      >
        {label}
      </label>
    )}
    <Input containerClassName={inputContainerClassName} {...props} />
  </div>
);
