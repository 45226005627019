/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLAttributes, ReactNode, useEffect, useRef } from "react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Header,
  Row,
  RowData,
  useReactTable,
} from "@tanstack/react-table";

import { cn } from "../../../utils";
import { LoadingScreen } from "../loading-screen";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../table";
import { RowContextConsumer, RowContextProvider } from "./row-context";
import { SortableHeader } from "./sortable-header";

interface TableContentProps<T = any> extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  isLoadingMore?: boolean;
  fetchMore?: () => void;
  data: T[];
  columns: ColumnDef<T>[];

  children?: ReactNode;
  className?: string;
  containerClassName?: string;
  expandedRowClassName?: string;
  rowClassName?: string;
  rightPanel?: ReactNode;
  emptyIndicator?: ReactNode;
  headerClassName?: string;
  hideHeaderOnEmpty?: boolean;
}

export const TableContent = <T,>({
  fetchMore,
  isLoading,
  isLoadingMore,
  data,
  columns,
  children,
  rightPanel,
  emptyIndicator,
  className,
  rowClassName,
  expandedRowClassName,
  containerClassName,
  headerClassName,
  hideHeaderOnEmpty,
  ...rest
}: TableContentProps<T>) => {
  const table = useReactTable({
    data: data ?? [],
    columns: (columns as unknown as ColumnDef<T>[]) ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  const lastTableRowElement = useRef<HTMLTableRowElement | null>(null);
  const isLastTableRowAlreadyVisible = useRef(false);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const currentElement = lastTableRowElement.current;

    if (!currentElement || isLoading || !fetchMore) {
      return;
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        const isVisible = entry.isIntersecting;

        if (isVisible && !isLastTableRowAlreadyVisible.current) {
          fetchMore();
        }

        isLastTableRowAlreadyVisible.current = isVisible;
      },
      {
        threshold: 1,
      }
    );

    observer.current.observe(currentElement);

    return () => {
      observer.current?.disconnect();
    };
  }, [fetchMore, isLoading, data?.length]);

  const renderHeader = (header: Header<T, RowData>) => {
    const headerContent = header.isPlaceholder
      ? null
      : flexRender(header.column.columnDef.header, header.getContext());

    if ((header.column.columnDef.meta as any)?.sortOptions) {
      return <SortableHeader headerContent={headerContent} header={header} />;
    }

    return headerContent;
  };

  const renderRowCells = (row: Row<T>) => {
    return row.getVisibleCells().map((cell, index) => (
      <TableCell
        key={cell.id}
        className={cn(
          "overflow-hidden py-2 text-xs text-muted-foreground",
          index === 0 && "pl-4",
          index === row.getVisibleCells().length - 1 && "pr-4"
        )}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </TableCell>
    ));
  };

  const renderRows = () => {
    const rows = table.getRowModel().rows;

    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={columns?.length} className="h-24 text-center">
            <LoadingScreen />
          </TableCell>
        </TableRow>
      );
    }

    if (rows?.length) {
      return rows.map((row, index) => {
        const isLastRow = row.index === rows.length - 1;

        return (
          <RowContextProvider row={row} key={row.id}>
            <RowContextConsumer>
              {({ isExpanded }) => (
                <>
                  <TableRow
                    className={cn(
                      "group h-[43px]",
                      isLastRow && "border-none",
                      rowClassName,
                      isExpanded && `${expandedRowClassName}`
                    )}
                    data-state={row.getIsSelected() && "selected"}
                    ref={
                      row.index === rows.length - 1
                        ? lastTableRowElement
                        : undefined
                    }
                  >
                    {renderRowCells(row)}
                  </TableRow>
                  {children && (
                    <TableRow
                      className={cn(
                        "border-none hover:bg-transparent",
                        isExpanded && expandedRowClassName
                      )}
                    >
                      <TableCell
                        className={"p-0 border-none"}
                        colSpan={columns?.length}
                      >
                        {children}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </RowContextConsumer>
          </RowContextProvider>
        );
      });
    }

    if (emptyIndicator) {
      return (
        <TableRow className="hover:bg-transparent">
          <TableCell
            colSpan={columns?.length}
            className="h-24 text-center text-muted-foreground text-base w-full"
          >
            {emptyIndicator}
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow>
        <TableCell
          colSpan={columns?.length}
          className="h-24 text-center text-muted-foreground text-base w-full"
        >
          <span className={"font-medium"}>
            We couldn’t find any results for the selected filters.
          </span>
          <br />
          <span>Please adjust your filters and try again</span>
        </TableCell>
      </TableRow>
    );
  };

  const renderHeaderGroups = () => {
    const rows = table.getRowModel().rows;

    if (hideHeaderOnEmpty && !rows?.length) {
      return null;
    }

    return table.getHeaderGroups().map((headerGroup) => (
      <TableRow
        key={headerGroup.id}
        className="border-b border-b-border z-10 shadow-sm"
        isHeader
      >
        {headerGroup.headers.map((header, index) => (
          <TableHead
            key={header.id}
            className={cn(
              "h-[56px] text-[12px] text-muted-foreground font-medium",
              index === 0 && "pl-4 rounded-tl-md",
              index === headerGroup.headers.length - 1 && "pr-4",
              headerClassName
            )}
            style={{
              width: header.getSize(),
            }}
          >
            {renderHeader(header)}
          </TableHead>
        ))}
      </TableRow>
    ));
  };

  return (
    <div
      className={cn(
        "rounded-md border flex-1 flex skip-close-upload min-h-full",
        containerClassName
      )}
      {...rest}
    >
      <Table className={cn("border-none", className)}>
        <TableHeader>{renderHeaderGroups()}</TableHeader>
        <TableBody>
          {renderRows()}
          {isLoadingMore && (
            <TableRow>
              <TableCell colSpan={columns?.length} className="h-24 text-center">
                <LoadingScreen />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {rightPanel}
    </div>
  );
};
