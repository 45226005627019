import React, { useContext } from "react";

import { Dialog, DialogContent } from "../actions/dialogs/dialog";
import { DialogDescription } from "../actions/dialogs/dialog-description";
import { DialogFooter } from "../actions/dialogs/dialog-footer";
import { DialogHeader } from "../actions/dialogs/dialog-header";
import { DialogTitle } from "../actions/dialogs/dialog-title";
import { Button } from "../content/button";
import { ErrorContext } from "./error-context";

export const ErrorDialog: React.FC = () => {
  const { open, onOpenChange, error } = useContext(ErrorContext);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle style={{ color: "hsl(var(--destructive))" }}>
            {error.title}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>{error.message}</DialogDescription>
        <DialogFooter>
          <Button
            variant={
              (error.acceptVariant as "primary" | "secondary") ?? "primary"
            }
            onClick={() => onOpenChange(false)}
          >
            {error.acceptLabel ?? "Accept"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
