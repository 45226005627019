import { FC, ReactNode } from "react";

import { DialogProps } from "@radix-ui/react-alert-dialog";

import { Button, ButtonVariant } from "../../content/button";
import { Spinner } from "../../content/spinner";
import { Dialog, DialogContent } from "./dialog";
import { DialogDescription } from "./dialog-description";

export interface ConfirmationDialogProps extends DialogProps {
  title: ReactNode;
  message: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  testId?: string;
  hideCancelButton?: boolean;
  options?: {
    confirmLabel?: ReactNode;
    cancelLabel?: ReactNode;
    confirmVariant?: ButtonVariant;
    cancelVariant?: ButtonVariant;
  };
}

const defaultOptions = {
  confirmLabel: "Confirm",
  cancelLabel: "Cancel",
  confirmVariant: "primary",
  cancelVariant: "ghost",
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  message,
  onConfirm,
  onCancel,
  onClose,
  isOpen,
  isLoading,
  testId,
  options = defaultOptions,
  hideCancelButton,
  ...rest
}) => {
  const actualOptions = { ...defaultOptions, ...options };

  return (
    <Dialog open={isOpen} onOpenChange={onClose} {...rest}>
      <DialogContent showCloseButton={false}>
        <DialogDescription className="flex flex-col gap-8" asChild>
          <div data-testid={testId} data-is-open={isOpen}>
            <div className="flex items-center gap-2">
              <div className="flex-1">
                <span
                  className="text-lg font-semibold text-slate-900"
                  data-testid={`${testId}-title`}
                >
                  {title}
                </span>
              </div>
            </div>
            <div data-testid={`${testId}-message`}>{message}</div>
            <div className="flex gap-2 justify-end">
              {!hideCancelButton && (
                <Button
                  variant={actualOptions.cancelVariant as ButtonVariant}
                  className={"skip-close-upload"}
                  onClick={onCancel}
                  data-testid={`${testId}-cancel-button`}
                >
                  {actualOptions.cancelLabel}
                </Button>
              )}
              <Button
                variant={actualOptions.confirmVariant as ButtonVariant}
                onClick={onConfirm}
                className={"skip-close-upload"}
                data-testid={`${testId}-confirm-button`}
              >
                {isLoading ? (
                  <Spinner size={4} data-testid={`${testId}-spinner`} />
                ) : (
                  actualOptions.confirmLabel
                )}
              </Button>
            </div>
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
