/* eslint-disable @typescript-eslint/no-explicit-any */
import { ForwardRefExoticComponent } from "react";

import { FCWithChildrenAndRef } from "@atlas-ui/types";

import { cn } from "../../utils";
import { Input } from "../actions/inputs/input";
import { Button } from "./button";
import { Card } from "./card";

interface CommandSectionProps {
  action?: () => void;
  onInputChange?: (value: string) => void;
  icon?: ForwardRefExoticComponent<any>;
  actionLabel?: string;
  placeholder?: string;
  inputValue?: string;
  inputClassName?: string;
  className?: string;
  contentClassName?: string;
  actionClassName?: string;
  cardInnerContainerClassName?: string;
  testId?: string;
}

export const CommandSection: FCWithChildrenAndRef<CommandSectionProps> = ({
  action,
  onInputChange,
  inputValue,
  icon: Icon,
  actionLabel,
  placeholder,
  className,
  contentClassName,
  inputClassName,
  actionClassName,
  cardInnerContainerClassName,
  testId,
  children,
}) => {
  const renderIcon = () => {
    if (!Icon) {
      return null;
    }

    return <Icon className="h-5 w-5 text-foreground" data-testid="icon" />;
  };

  return (
    <Card
      data-testid={testId ?? "command-section"}
      className={cn("flex flex-col", className)}
    >
      <div
        className={cn(
          "flex p-2 gap-2 items-center border-b-muted border-b",
          cardInnerContainerClassName
        )}
      >
        {renderIcon()}
        <Input
          className={cn("border-none h-5 pl-0", inputClassName)}
          containerClassName="flex-1"
          placeholder={placeholder ?? "Search"}
          data-testid="command-input"
          onChange={(e) => onInputChange?.(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              action?.();
            }
          }}
          value={inputValue}
        />
        <Button
          size="md"
          className={cn("min-w-[90px] max-h-[27px]", actionClassName)}
          onClick={action}
        >
          {actionLabel ?? "Add"}
        </Button>
      </div>
      <div className="flex p-2">
        <span className="text-xs text-foreground">Suggestions</span>
      </div>
      <div
        data-testid="command-section-content"
        className={cn("flex gap-2 px-4 py-2", contentClassName)}
      >
        {children}
      </div>
    </Card>
  );
};
