import { FC } from "react";

import { Input } from "./input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";

interface TermProps {
  value: string | undefined;
  onChange: (value: string) => void;
}

export const Term: FC<TermProps> = ({ value, onChange }) => {
  return (
    <div className="flex gap-2">
      <Input
        type="number"
        min={1}
        className="w-14 h-8 px-2"
        onChange={(e) => {
          const fieldValue = e.target.value;
          const [, unit] = (value || "").split(" ");
          onChange(`${fieldValue} ${unit || "Days"}`);
        }}
        value={(value || "").split(" ")[0] || ""}
      />
      <Select
        onValueChange={(fieldValue) => {
          const [number] = (value || "").split(" ");
          onChange(`${number || "1"} ${fieldValue}`);
        }}
        value={(value || "").split(" ")[1] || ""}
      >
        <SelectTrigger className="w-[120px]" size={"sm"}>
          <SelectValue placeholder="Select unit" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="Days">Days</SelectItem>
          <SelectItem value="Months">Months</SelectItem>
          <SelectItem value="Years">Years</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
