import { FC } from "react";

import { isNil } from "lodash";

import { Tabs, TabsList, TabsTrigger } from "../../content/tabs";

interface BooleanSelectorProps {
  value: string | undefined;
  onChange: (value: "Yes" | "No") => void;
  yesLabel?: string;
  noLabel?: string;
}

export const BooleanSelector: FC<BooleanSelectorProps> = ({
  value,
  onChange,
  yesLabel = "Yes",
  noLabel = "No",
  ...props
}) => {
  return (
    <Tabs
      className={"max-w-[102px]"}
      value={isNil(value) ? undefined : value.toString()}
      onValueChange={(newValue) => onChange(newValue as "Yes" | "No")}
      {...props}
      itemType={"boolean"}
    >
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="Yes">{yesLabel}</TabsTrigger>
        <TabsTrigger value="No">{noLabel}</TabsTrigger>
      </TabsList>
    </Tabs>
  );
};
