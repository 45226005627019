"use client";

import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from "react";

import { ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  Content,
  Header,
  Item,
  Root,
  Trigger,
} from "@radix-ui/react-accordion";

import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

import { cn } from "../../utils";

const Accordion = Root;

interface AccordionItemProps extends ComponentPropsWithoutRef<typeof Item> {
  className?: string;
  variant?: "default" | "bordered" | "borderless";
}

const accordionItemVariants = cva("relative", {
  variants: {
    variant: {
      default: "border-b",
      bordered: "border rounded-md",
      borderless: "border-0",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const AccordionItem = forwardRef<ElementRef<typeof Item>, AccordionItemProps>(
  ({ className, variant, ...props }, ref) => (
    <Item
      ref={ref}
      className={accordionItemVariants({ className, variant })}
      {...props}
    />
  )
);

AccordionItem.displayName = "AccordionItem";

const accordionTriggerVariants = cva(
  "flex flex-1 items-center justify-between font-medium transition-all [&[data-state=open]>svg]:rotate-180",
  {
    variants: {
      variant: {
        default: "py-0",
        bordered: "px-4 py-2 text-lg font-semibold text-left",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

interface AccordionTriggerProps
  extends ComponentPropsWithoutRef<typeof Trigger> {
  className?: string;
  variant?: "default" | "bordered";
  iconClassName?: string;
  extras?: ReactNode[];
  hideIcon?: boolean;
}

const AccordionTrigger = forwardRef<
  ElementRef<typeof Trigger>,
  AccordionTriggerProps
>(
  (
    {
      className,
      variant,
      children,
      iconClassName,
      extras,
      hideIcon = false,
      ...props
    },
    ref
  ) => (
    <Header className="flex text-sm">
      <Trigger
        ref={ref}
        className={accordionTriggerVariants({ className, variant })}
        {...props}
      >
        {children}
        {extras}
        {!props.disabled && !hideIcon && (
          <ChevronDownIcon
            className={twMerge(
              "absolute right-0 h-6 w-6 shrink-0 transition-transform duration-200",
              iconClassName
            )}
          />
        )}
      </Trigger>
    </Header>
  )
);

AccordionTrigger.displayName = Trigger.displayName;

const AccordionContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content>
>(({ className, children, ...props }, ref) => (
  <Content
    ref={ref}
    className={cn(
      "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className
    )}
    {...props}
  >
    <div className="pb-4 pt-0">{children}</div>
  </Content>
));

AccordionContent.displayName = Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
