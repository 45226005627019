import { FC, ReactNode } from "react";

import { AnimatePresence, EasingDefinition, motion } from "framer-motion";

export interface CollapseProps {
  children: ReactNode;
  isOpen: boolean;
  enableOpacity?: boolean;
  duration?: number;
  ease?: EasingDefinition;
  className?: string;
  testId?: string;
  customHeight?: string | number;
}

export const Collapse: FC<CollapseProps> = ({
  children,
  isOpen,
  enableOpacity = true,
  duration = 0.2,
  ease = "easeOut",
  className,
  testId,
  customHeight,
}) => {
  const opacityIfEnabled = isOpen ? 1 : 0;
  const height = isOpen ? customHeight || "auto" : 0;

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          data-testid={testId}
          initial={{ height: 0, opacity: enableOpacity ? 0 : 1 }}
          animate={{
            height,
            opacity: enableOpacity ? opacityIfEnabled : 1,
            overflow: "hidden",
          }}
          exit={{
            height: 0,
            opacity: enableOpacity ? 0 : 1,
            overflow: "hidden",
          }}
          transition={{ duration, ease }}
          className={className}
          data-open={isOpen}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
