import { FC, PropsWithChildren, ReactNode } from "react";

import { cn } from "../../utils";
import { Breadcrumbs, BreadcrumbUnion } from "../content";

interface PageTitleError {
  title?: string;
  subtitle?: string;
}

export interface PageTitleProps {
  breadcrumbs?: BreadcrumbUnion[];
  title: ReactNode;
  error?: PageTitleError;
  icon?: ReactNode;
  containerClassName?: string;
  innerContainerClassName?: string;
}

export const PageTitle: FC<PropsWithChildren<PageTitleProps>> = ({
  title,
  error,
  icon,
  breadcrumbs,
  children,
  containerClassName,
  innerContainerClassName,
}) => {
  return (
    <div
      className={cn("flex flex-col gap-1", containerClassName)}
      data-testid="page-title"
    >
      {breadcrumbs && (
        <div data-testid="breadcrumbs">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      )}
      <div className="flex">
        <div className="flex items-center">
          {icon}
          <h1 className="text-2xl font-semibold py-1" data-testid="title">
            {title}
          </h1>
        </div>
        <div
          className={cn(
            "flex-1 justify-end items-center flex gap-4",
            innerContainerClassName
          )}
        >
          {children}
        </div>
      </div>
      {error && (
        <div data-testid="error">
          <p className="text-sm flex">
            <span
              className="text-destructive font-semibold"
              data-testid="error-title"
            >
              {error.title}
            </span>
            {error.subtitle && (
              <span className="ml-1" data-testid="error-subtitle">
                {error.subtitle}
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};
